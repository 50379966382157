import * as React from 'react'
import PropTypes from 'prop-types'

import EventSlider from '@/components/Club/Events/Slider'
import EventCard from '@/components/Club/Events/Card'

import {
  Container,
  Wrapper,
  GridContainer,
  Content,
  Subtitle,
  Title,
  Wysiwyg
} from './style'

const Events = ({ items = [], title = '', subtitle = '', text = '' }) => {
  return (
    <Container>
      <GridContainer>
        <Wrapper>
          <Content>
            {
              !!subtitle.length && (
                <Subtitle dangerouslySetInnerHTML={ { __html: subtitle } } />
              )
            }
            {
              !!title.length && (
                <Title dangerouslySetInnerHTML={ { __html: title } } />
              )
            }
            {
              !!text.length && (
                <Wysiwyg>{ text }</Wysiwyg>
              )
            }
          </Content>
        </Wrapper>
      </GridContainer>
      <EventSlider items={ items } />
    </Container>
  )
}

Events.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    image: PropTypes.string,
    link: PropTypes.string
  }))
}

export default Events
