import styled from '@emotion/styled'
import { Swiper } from 'swiper/react'

import { mediaMax } from '@/styles/mixins'

import 'swiper/css'
import 'swiper/css/virtual'

import {
  LeftArrow,
  RightArrow,
  Wrapper as ArrowWrapper
} from '@/components/Block/Sliders/atoms/Arrows/style'

import { StyledGridContainer } from '@/components/Grid'


export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  ${StyledGridContainer}{
    ${mediaMax.xs}{
      padding: 0;
      padding-left: ${p => p.theme.paddingX.m};
    }
  }
`

export const SwiperContent = styled(Swiper)`
  width: 100%;
  overflow: visible !important;
  ${mediaMax.xs} {
    overflow: hidden !important;
  }

  .swiper-wrapper {
    align-items: stretch;
  }

  .swiper-slide {
    height: auto;
    width: 27vw;
    margin-right: 2rem;
    ${mediaMax.xs}{
      width: calc(100% / 1.2);
    }
  }
`

export const ActionWrapper = styled.div`
  margin-top: 1.6rem;
  width: 100%;

  ${ArrowWrapper} {
    justify-content: flex-start !important;
    flex-direction: row !important;

    ${LeftArrow},
    ${RightArrow} {
      transform: rotate(0deg) !important;
    }
    ${mediaMax.xs}{
      display: none;
    }
  }
`
