import resolveAssetURL from '@/utils/resolveAssetURL'
import PropTypes from 'prop-types'
import * as React from 'react'

import BlockText from '@/components/Block/Text'

import {
  Container,
  Image,
  LeftWrapper,
  RightWrapper
} from './style'

const TextImages = ({
  title = '',
  subtitle = '',
  actions = [],
  text = '',
  image = '',
  alt = '',
  display = 'regular'
}) => {
  return (
    <Container className={ display }>
      <LeftWrapper>
        <BlockText
          title={ title }
          titleTag='h2'
          subtitle={ subtitle }
          actions={ actions }
          text={ text }
        />
      </LeftWrapper>
      <RightWrapper>
        <Image loading='lazy' src={ resolveAssetURL(image) } alt={ alt } />
      </RightWrapper>
    </Container>
  )
}

TextImages.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  actions: PropTypes.array,
  text: PropTypes.string,
  image: PropTypes.string,
  alt: PropTypes.string,
  display: PropTypes.oneOf(['regular', 'inverted', 'big', 'small'])
}

export default TextImages
