import styled from '@emotion/styled'
import { mediaMax } from '@/styles/mixins'

import {
  Subtitle as TextSubtitle,
  Title as TextTitle,
  Wysiwyg as TextWysiwyg,
  Action as TextAction
} from '@/components/Block/Text/style'

import {
  ButtonWrapper as Btn
} from '@/components/Button/style'

export const Wrapper = styled.div`
  width: calc(100% / 2);
  display: flex;
  justify-content: center;
`

export const Image = styled.img`
  width: 100%;
  display: flex;
  align-items: center;
  object-fit: cover;
`

export const CenterWrapper = styled(Wrapper)`
  position: relative;
  padding-left: 5%;
  margin-top: 6rem;
  margin-bottom: 2rem;
  ${mediaMax.sm} {
    width: 100%;
  }
`

export const LeftWrapper = styled(Wrapper)`
  position: relative;
  ${mediaMax.xs} {
    width: 100%;
    padding: 0 5vw;

    ${Btn} {
      width: 100%;
    }
  }
`

export const RightWrapper = styled(Wrapper)`
  position: relative;
  height: 100%;

  ${mediaMax.xs} {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 5vw;
  }

  ${Image} {
    width: 100%;
  }
`

export const Container = styled.div`
  margin: 12rem 0;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 0 10rem;

  ${mediaMax.md} {
    padding: 0 ${p => p.theme.paddingX.t};
    overflow: hidden;
  }

  ${mediaMax.xs} {
    flex-direction: column;
    margin: 3rem 0;
    padding: 0;
    gap: 0rem;

    ${Wrapper} {
      width: 100%;

      ${TextSubtitle} {
          font-size: 1.1rem;
      }

      ${TextTitle} {
          margin-bottom: 1.5rem;
      }

      ${TextWysiwyg} {
          width: 90%;
      }

      ${TextAction} {
          margin-top: 4rem;
      }

      ${Btn}{
        width: 100%;
        padding: 1.7rem 3.5rem;
      }
    }
  }
`
