import styled from '@emotion/styled'

import { sectionTitle, sectionSubtitle, mediaMax } from '@/styles/mixins'

import { GridContainer as GridContainerBase } from '@/components/Grid'
import WysiwygBase from '@/components/Wysiwyg'

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  margin-bottom: 8.4rem;
  padding: 6.7rem 0;
  background-color: ${({ theme }) => theme.colors.lightVanilla};
`

export const GridContainer = styled(GridContainerBase)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  ${mediaMax.xs}{
    width: 100%;
    margin-top: 3rem;
  }
`

export const Subtitle = styled.h3`
  ${sectionSubtitle};
  text-transform: uppercase;
  margin-bottom: 3rem;
  letter-spacing: 0.2rem;
`

export const Title = styled.h3`
  ${sectionTitle};
  font-size: 4.5rem;
  color: ${({ theme }) => theme.colors.brown};
  text-transform: uppercase;
  margin-bottom: 2.5rem;
`

export const Text = styled(WysiwygBase)`
  width: 70%;
  color: ${({ theme }) => theme.colors.brown};

  ${mediaMax.xs}{
    width: 100%;
  }
`

export const ListWrapper = styled(Wrapper)`
  margin-top: 6.4rem;
  padding: 0 10rem;

  ${mediaMax.xs}{
    padding: 0;
  }
`
