import * as React from 'react'
import PropTypes from 'prop-types'

import resolveAssetURL from '@/utils/resolveAssetURL'

import Link from '@/components/LocalizedGatsbyLink'
import isExternalLink from '@/utils/isExternalLink'
import isPdf from '@/utils/isPdf'

import { useTheme } from '@emotion/react'

import Button from '@/components/Button'

import {
  Container,
  SubContainer,
  ImageWrapper,
  Image,
  ContentWrapper,
  TagsWrapper,
  Tag,
  InfosWrapper,
  Title,
  ButtonWrapper
} from './style'

const EventCard = ({ item }) => {
  return (
    <Container>
      <SubContainer>
        <ImageWrapper
          title={ item.name }
        >
          {/* <IconWrapper href={ `mailto:?body=${item.link}&subject=${item.ref}` } target='_blank'>
          <Icon
            name='share'
            size={ 20 }
            color={ `${theme.colors.white}` }
          />
        </IconWrapper> */}
          <Image loading='lazy' src={ resolveAssetURL(item.image.url) } alt={ item.image.alt } />
        </ImageWrapper>
        <ContentWrapper>
          <TagsWrapper>
            { item.tags.map((tag, i) => (
              <Tag key={ i }>{ tag }</Tag>
            )) }
          </TagsWrapper>
          <InfosWrapper>
            <Title
              title={ item.name }
            >
              {item.name}
            </Title>
          </InfosWrapper>
        </ContentWrapper>
      </SubContainer>
      <ButtonWrapper>
        {
          (item.tock && !item.disabled) && (
            <Button type='white' className='cta-experience' to={ item.tock.url }>{ item.tock.label || 'Book Now' }</Button>
          )
        }
        {
          !item.disabled && (
            <Button className='cta-experience' to={ item.link.url }>{ item.link.label || 'Learn more' }</Button>
          )
        }
      </ButtonWrapper>
    </Container>
  )
}

EventCard.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    image: PropTypes.object,
    link: PropTypes.object,
    tock: PropTypes.object,
    disabled: PropTypes.bool
  })
}

export default EventCard
