import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Icon from '@/components/Icon'
import { useTheme } from '@emotion/react'

import { HTML_POPUP_ID } from '@/components/Popup/config'
import { popupManager, popupDataManager } from '@/recoil/popup'

import { useSetRecoilState } from 'recoil'

import resolveAssetURL from '@/utils/resolveAssetURL'

import imageCercle from '@/images/logo-cloudy-bay-circle-2.svg'

import {
  Container,
  GridContainer,
  SubContainer,
  TopContainer,
  TopWrapper,
  Subtitle,
  Title,
  Text,
  Wrapper,
  Item,
  IconWrapper,
  ItemTitle,
  ItemText,
  BottomContainer,
  Image,
  NavContainer,
  NavItem,
  LogoCercle
} from './style'
import Button from '@/components/Button'
import Link from '@/components/Link'
import { navigate } from 'gatsby'
import {useLocation} from "@reach/router";

function Benefits ({
  title = '',
  subtitle = '',
  text = '',
  className,
  items = []
}) {
  const location = useLocation()
  const tabHash = parseInt(location.hash.replace('#terms-', ''))
  const [tabActive, setTab] = useState(tabHash || 1)
  const setPopup = useSetRecoilState(popupManager)
  const setPopupData = useSetRecoilState(popupDataManager)

  const closeTermsPopup = _ => {
    history.pushState({}, null, location.href.replace(`#terms-${tabActive}`, ''))
  }

  const openTermsPopup = (title, content) => {
    if (location.href.indexOf(`#terms-${tabActive}`) === -1) {
      history.pushState({}, null, location.href + `#terms-${tabActive}`)
    }

    setPopupData({ title, subtitle: '', content, closeCallback: closeTermsPopup, bob: 'hey' })
    setPopup(HTML_POPUP_ID)
  }

  useEffect(() => {
    if (location.pathname.split('/')[1] !== 'en-nz') {
      navigate(`/en-nz/wine-club/${location.search}`)
    }

    if (location.href.indexOf(`#terms-${tabActive}`) > -1) {
      openTermsPopup(items[tabActive].popin.title, items[tabActive].popin.text)
    }
  }, [])

  const handleNavClick = (index) => {
    setTab(index)
  }

  return (
    <Container>
      <LogoCercle loading='lazy' src={ imageCercle } alt='logo cercle' />
      <GridContainer>
        <TopContainer>
          <TopWrapper>
            <Subtitle>
              { subtitle }
            </Subtitle>
            <Title>
              { title }
            </Title>
            <Text>
              { text }
            </Text>
          </TopWrapper>
        </TopContainer>
        <NavContainer>
          {
            items.map((tab, i) => (
              <NavItem
                key={ i }
                onClick={ () => handleNavClick(i) }
                className={ `${tabActive === i && 'active'}` }
              >
                { tab.title }
              </NavItem>
            ))
          }
        </NavContainer>

        {
          items.map((tab, i) => (
            <SubContainer key={ i } className={ `${i === tabActive && 'active'}` }>
              <Wrapper>
                { tab.items.map((item, i) => (
                  <Item key={ i }>
                    <IconWrapper>
                      {/* <Icon size={ 50 } name={ item.icon }  color={  theme.colors.orange } /> */}
                      <Image loading='lazy' src={ resolveAssetURL(item.image.url) } alt={ item.image.alt || '' } />
                    </IconWrapper>
                    <ItemTitle>
                      { item.title }
                    </ItemTitle>
                    <ItemText>
                      { item.text }
                    </ItemText>
                  </Item>
                )) }
              </Wrapper>
              <BottomContainer className='many'>
                <Button
                  to={ tab.link.url }
                  target='_blank'
                >
                  {tab.link.label}
                </Button>
                <Link
                  onClick={ () => openTermsPopup(tab.popin.title, tab.popin.text) }
                >
                  {tab.terms.label}
                </Link>
              </BottomContainer>
            </SubContainer>
          ))
        }
      </GridContainer>
    </Container>
  )
}

Benefits.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    popin: PropTypes.shape({ title: PropTypes.string, text: PropTypes.string }),
    link: PropTypes.shape({ url: PropTypes.string, label: PropTypes.string }),
    terms: PropTypes.shape({ url: PropTypes.string, label: PropTypes.string }),
    items: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      image: PropTypes.shape({ url: PropTypes.string, alt: PropTypes.string })
    }))
  }))
}

export default Benefits
