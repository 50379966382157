import styled from '@emotion/styled'
import { mediaMax } from '@/styles/mixins'

import { Content as WysiwygContent } from '@/components/Wysiwyg/style'
import { ActionWrapper as SliderActionWrapper } from '@/components/Block/Sliders/ImageSlider/style'
import { Wrapper as ArrowWrapper } from '@/components/Block/Sliders/atoms/Arrows/style'
import { Actions, Title as BlockTitle } from '@/components/Block/Text/style'
import { ButtonWrapper as Button } from '@/components/Button/style'

export const LeftWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10%;
  position: relative;

  ${SliderActionWrapper} {
    padding-top: 3rem;
  }

  ${mediaMax.xs}{
    width: 100%;
    padding: 0 5vw;

    .swiper-slide {
      width: 100% !important;
    }
  }

  .circularLabelButton {
    position: absolute;
    bottom: -5%;
    right: 10%;
    z-index: 2;

    text {
      fill: #3f787b;
    }
  }
`

export const RightWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5%;
  padding-right: 15px;

  ${Actions} {
    justify-content: flex-start;
    ${Button} {
      width: auto;
    }
  }

  ${WysiwygContent} {
    width: 75%;
    ${mediaMax.xs}{
      width: 100%;
    }
  }

  ${BlockTitle} {
    margin-bottom: 2.4rem;
  }

  ${mediaMax.xs}{
    width: 100%;
    padding-left: ${p => p.theme.paddingX.m};
    padding-right: ${p => p.theme.paddingX.m};
  }
`
export const Container = styled.div`
  margin: 0 0 12rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6rem 0;
  padding-bottom: 12rem;
  background-color: ${({ theme }) => theme.colors.lightGrey4};

  ${mediaMax.xs}{
    gap: 6rem;
    flex-direction: column-reverse;
    margin-bottom: 4rem;
    margin-top: 0;
  }

  &.regular {
    ${ArrowWrapper} {
      justify-content: flex-start;
    }
  }

  &.inverted {
    flex-direction: row-reverse;

    ${mediaMax.xs}{
      flex-direction: column-reverse;
    }

    ${LeftWrapper} {
      padding-right: 0;
      padding-left: 10%;

      ${mediaMax.xs}{
        padding-left: ${({ theme }) => theme.paddingX.m};
      }
    }

    ${RightWrapper} {
      padding-right: 15px;
      padding-left: 10%;

      ${mediaMax.xs}{
        padding-right: ${p => p.theme.paddingX.m};
        padding-left:  ${p => p.theme.paddingX.m};
      }
    }
  }
`
