import React, { useLayoutEffect, useRef } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import PropTypes from 'prop-types'

import { Container, Item } from './style'
import { headerHeightState, stickyHeightState, subNavIdState } from '@/recoil/header'

export default function SectionsNav ({ sections, gtm, wineName }) {
  const containerRef = useRef()
  const setStickyHeight = useSetRecoilState(stickyHeightState)
  const headerHeight = useRecoilValue(headerHeightState)
  const setSubNavId = useSetRecoilState(subNavIdState)

  // function scrollTo (e, $el) {
  //   e.preventDefault()
  //   $el.scrollIntoView({
  //     behavior: 'smooth',
  //     block: 'start'
  //   })
  // }

  function scrollTo (e, $el) {
    e.preventDefault()

    const navbarHeight = document.getElementById('sections-nav').offsetHeight + 80
    const yCoordinate = $el.getBoundingClientRect().top + window.scrollY

    window.scrollTo({
      top: yCoordinate - navbarHeight,
      block: 'start',
      behavior: 'smooth'
    })
  }

  function handleMouseLeave (e) {
    const $subNavs = document.querySelectorAll('.subnav')
    if ($subNavs) {
      const $hoveredEl = e.relatedTarget
      $subNavs.forEach($subNav => {
        if ($hoveredEl !== $subNav) setSubNavId(null)
      })
    }
  }

  useLayoutEffect(() => {
    if (containerRef.current) {
      setStickyHeight(containerRef.current.getBoundingClientRect().height)
    }
  }, [containerRef])

  return (
    <Container id='sections-nav' ref={ containerRef } headerHeight={ headerHeight } onMouseLeave={ handleMouseLeave }>
      {sections.length > 0 && sections.map(({ name, id, isActive, ref }) => (
        <Item
          href={ `#${id}` }
          key={ id }
          title={ name }
          className={ isActive ? 'active' : '' }
          onClick={ (e) => {
            setSubNavId(null)
            scrollTo(e, ref.current)
            gtm(`Sticky Nav > ${name}`, wineName)
          } }
        >
          {name}
        </Item>
      ))}
    </Container>
  )
}

SectionsNav.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired
  })).isRequired
}
