import styled from '@emotion/styled'
import { mediaMax } from '@/styles/mixins'

export const Container = styled.div`
    position: sticky;
    top: ${p => p.headerHeight + 'px'};
    background: ${p => p.theme.colors.white};
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    width: 100vw;
    z-index: 99;
    overflow: hidden;
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.2);
    ${mediaMax.xs}{
        display: none;
    }
`

export const Item = styled.a`
    color: ${p => p.theme.colors.black}60;
    height: 100%;
    padding: 2rem 1rem;
    text-transform: uppercase;
    font-family: ${p => p.theme.fonts.sansSerif};
    font-size: 1.2rem;
    text-decoration: none;
    letter-spacing: 1px;
    position: relative;
    ${mediaMax.md}{
        font-size: 1rem;
        text-align: center;
    }
    &.active{
        color: ${p => p.theme.colors.green};
        &::after{
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: ${p => p.theme.colors.green};
        }
    }
`
