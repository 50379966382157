import { isMobileState } from '@/recoil/layout'
import { useLocation } from '@reach/router'
import { graphql } from 'gatsby'
import React, { useRef } from 'react'
import { Helmet } from 'react-helmet'

import Layout from '@/layouts/Default'

import BlockSlider from '@/components/Block/TextSlider'
import Button from '@/components/Button'
import Hero from '@/components/Hero'
import { popupDataManager, popupManager } from '@/recoil/popup'
import resolveAssetURL from '@/utils/resolveAssetURL'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import Benefits from '@/components/Club/Benefits'
import Contact from '@/components/Club/Contact'
import EventsSlider from '@/components/Club/Events'
import Faq from '@/components/Club/Faq'
import BlockTextSlider from '@/components/Club/TextSlider'

import WinerySlider from '@/components/WinerySlider'
import WinesSlider from '@/components/WinesSlider'

import SectionsNav from "@/components/SectionsNav"
import { useWatchSections } from "@/hook"
import {
  Wrapper
} from '@/styles/pages/wine-club/style'
import moment from "moment/moment"

const VisitWinery = ({ data, pageContext: { pagePath, marketCode: pageMarketCode }  }) => {
  const isMobile = useRecoilValue(isMobileState)
  const setPopup = useSetRecoilState(popupManager)
  const setPopupData = useSetRecoilState(popupDataManager)
  const location = useLocation()

  const meta = {
    title: data.drupal.nodeById?.fieldWineClubMetaTags?.entity?.fieldMetaTitle,
    description: data.drupal.nodeById?.fieldWineClubMetaTags?.entity?.fieldMetaDescription,
    bypassAgeGate: data.drupal.nodeById?.fieldWineClubMetaTags?.entity?.fieldMetaTagsBypassAgeGate,
    slug: data.drupal.nodeById?.fieldWineClubMetaTags?.entity?.fieldMetaSlugUrl,
    image: data.drupal.nodeById?.fieldWineClubMetaTags?.entity?.fieldMetaOpenGraphImage?.url
  }
  const heroData = data.drupal.nodeById.fieldWineClubHeroBlock?.entity
  const introData = data.drupal.nodeById.fieldWineClubIntroBlock?.entity
  const faqData = data.drupal.nodeById.fieldWineClubFaqBlock?.entity
  const faqItemsData = faqData.fieldFaqListItem.map((item) => ({
    title: item.entity.fieldFaqItemQuestion,
    text: item.entity.fieldFaqItemAnswer
  }))
  const selectionData = data.drupal.nodeById.fieldWineClubSelectionBlock?.entity
  const selectionSlidesData = selectionData?.fieldWineClubSelectDetails.map((item) => ({
    title: item.entity.fieldWineClubSelectTitle,
    text: item.entity.fieldWineClubSelectionDesc,
    selector: `${item.entity.fieldWineClubSelectionMonth} ${item.entity.fieldWineClubSelectionYear}`,
    list: Array.from(selectionData?.fieldWineClubSelectDetails, item => `${item.entity.fieldWineClubSelectionMonth} ${item.entity.fieldWineClubSelectionYear}`),
    images:  Array.from(item.entity?.fieldWineClubImageCarrousel.entity?.fieldCarrouselImageItem, item => item.entity.fieldImageItem?.url),
    url: resolveAssetURL(item.entity.fieldWineClubTastingNotes.entity?.url),
    ctaLabel: selectionData?.fieldWineClubSelectDetails[0].entity?.fieldTastingNotesCtaLabel,
    recommendation: Array.from(item.entity?.fieldWineClubWineList, item => ({ title: item.entity.title, type: item.entity.entityBundle, url: item.entity?.path?.alias, special: !!item.entity?.fieldWineProductSpecial })).sort((a, b) => (a.type === "wine_product") ? -1 : 0),
    source: item.entity.fieldWineClubSelectionVideo?.uri,
    videoLabel: item.entity.fieldWineClubSelectionVideo?.title
  }))

  const visitUsItems = data.drupal.nodeById.fieldWineClubVisitUsBlock.entity?.fieldVisitUsItemList.map((item) => ({
    name: item.entity.fieldVisitUsItemTitle,
    background: isMobile ? item.entity.fieldVisitUsItemBackMobile?.url : item.entity.fieldVisitUsItemBackDesk?.url,
    backgroundAlt: isMobile ? item.entity.fieldVisitUsItemBackMobile?.alt : item.entity.fieldVisitUsItemBackDesk?.alt,
    image: item.entity.fieldVisitUsItemCoverImage?.url,
    imageAlt: item.entity.fieldVisitUsItemCoverImage?.alt,
    href: item.entity.fieldVisitUsItemCta?.url?.path
  }))

  const winesData = {
    subtitle: data.drupal.nodeById.fieldWineClubOurWinesSub,
    title: data.drupal.nodeById.fieldWineClubOurWinesTitle,
    text: data.drupal.nodeById.fieldWineClubOurWinesText,
  }

  const winesItems = data.drupal.productsQuery.entities.map((category) => (
    {
      img: category.fieldWineCatPrimaryVintage.entity.fieldWineProdPackshotShadow.url,
      art: category.fieldWineCatPrimaryVintage.entity.fieldWineProductBackillustrat.url,
      name: category.name,
      price: category.fieldWineCategoryFromPrice,
      colorBg: category.fieldWineCatPrimaryVintage.entity.fieldWineProductBackcolor,
      colorCta: category.fieldWineCatPrimaryVintage.entity.fieldProductDiscoverColorCta,
      title: category.fieldWineCatPrimaryVintage.entity.fieldCategoryProductName,
      text: category.fieldWineCatPrimaryVintage.entity.fieldWineProductShortDesc,
      path: category.fieldWineCatPrimaryVintage.entity.path.alias
    }
  ))

  const eventData = {
    title: data.drupal.nodeById.fieldWineClubEventTitle,
    subtitle: data.drupal.nodeById.fieldWineClubEventSubtitle,
    text: data.drupal.nodeById.fieldWineClubEventText
  }
  const eventItems = data.drupal.nodeById.fieldWineClubEventItems.map((e) => {
    const event = e.entity.fieldEventItem.entity

    let tock = null

    if (event.fieldEventCardLinkTock) {
      tock = { label: event.fieldEventCardLinkTock.label, url: event.fieldEventCardLinkTock.url.path }
    }

    let tag = ''

    if (event?.fieldEventStartDate?.value) {
      tag += `${moment(event?.fieldEventStartDate?.value).format('DD MMM. YYYY')}`
    }

    if (event?.fieldEventEndDate?.value) {
      tag += ` - ${moment(event?.fieldEventEndDate?.value).format('DD MMM. YYYY')}`
    }

    if (tag.length > 0) {
      tag += ` - ${event.fieldEventLocation}`
    } else {
      tag += `${event.fieldEventLocation}`
    }

    return {
      name: event.title,
      tags: [tag],
      image: event.fieldEventCoverMobile,
      link: {
        label: event.fieldEventCardLinkLabel,
        url: event?.path?.alias,
      },
      tock,
      disabled: !!event.fieldEventCardDisable
    }
  })


  const contactData = {
    title: data.drupal.nodeById.fieldWineClubContactUsBlock.entity.fieldContactBlockTitle,
    subtitle: data.drupal.nodeById.fieldWineClubContactUsBlock.entity.fieldContactBlockSubtitle,
    action: data.drupal.nodeById.fieldWineClubContactUsBlock.entity.fieldContactBlockLink,
    text: data.drupal.nodeById.fieldWineClubContactUsBlock.entity.fieldContactBlockText,
    image: data.drupal.nodeById.fieldWineClubContactUsBlock.entity.fieldContactBlockImage.url,
    alt: data.drupal.nodeById.fieldWineClubContactUsBlock.entity.fieldContactBlockImage.alt,
  }

  const benefitsData = data.drupal.nodeById.fieldWineClubBenefits?.entity

  const benefits = {
    title: benefitsData.fieldWineClubBenefitTitle,
    subtitle: benefitsData.fieldWineClubBenefitSubtitle,
    text: benefitsData.fieldWineClubBenefitDesc,
    list: benefitsData.fieldWineClubBenefitsList.map(t => {
      const tab = t.entity
      return {
        title: tab.fieldWineClubBenefitCat,
        popin: {
          title: tab.fieldWclubTermsPopinTitle,
          text: tab.fieldWclubTermsPopinText
        },
        link: {
          url: tab.fieldWineClubBenefitListLnk.uri,
          label: tab.fieldWineClubBenefitListLnk.title
        },
        terms: {
          url: tab.fieldWineClubBenefitListTrm.uri,
          label: tab.fieldWineClubBenefitListTrm.title
        },
        items: tab.fieldWineClubBenefitList.map(item => {
          const benefit = item.entity

          return {
            title: benefit.fieldWineClubBenefitLabel,
            text: benefit.fieldStandardDescription,
            image: benefit.fieldWineClubBenefitImage
          }
        })
      }
    })
  }

  const richResultMainEntity = faqItemsData.map(({text, title}) => {
    return {
      "@type": "Question",
      "name": title,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": text
      }
    }
  })

  const richResultSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": richResultMainEntity
  }

  const introBtns = introData.fieldWineClubIntroCta.map((btn, key) => {
    const label = btn.title
    const url = btn.url.path
    const type = (label.toLowerCase().includes('login')) ? 'white' : 'green'

    return (
      <Button type={ type } key={ key } to={ url } target="_blank">{label}</Button>
    )
  })

  const wineClubRef = useRef()
  const membershipRef = useRef()
  const eventsRef = useRef()
  const selectionRef = useRef()
  const exploreRef = useRef()
  const faqRef = useRef()
  const contactRef = useRef()

  const [Section, sections] = useWatchSections(
    [ wineClubRef,  membershipRef,  eventsRef,  selectionRef,  exploreRef,  faqRef,  contactRef ],
    {
      offsetTop: 132
    }
  )

  return (
    <Layout entityBundle='wine_club' path={ pagePath } pageMarketCode={ pageMarketCode }>
      <Helmet>
        <title>{meta.title}</title>
        <meta name='description' content={ meta.description } />
        <meta property='og:title' content={ meta.title } />
        <meta property='og:description' content={ meta.description } />
        <meta property='og:url' content={ `${process.env.URL || process.env.GATSBY_DRUPAL_URL}${pagePath}` } />
        <meta property='og:site_name' content='Cloudy Bay' />
        <meta property='og:type' content='website' />
        { meta.image && <meta property='og:image' content={meta.image} />}
        <script type="application/ld+json">
          {JSON.stringify(richResultSchema)}
        </script>
      </Helmet>
      <Wrapper>
        {/*<img src="/test.jpeg" style={{ position: 'absolute',width: '100%',top: '0',left: '0',opacity: '.5', zIndex: 999, pointerEvents: 'none' }}/>*/}
        <Hero
          id="textcercle1"
          wineClub={ true }
          subtitle={heroData?.fieldHeroSubtitle}
          title={heroData?.fieldHeroTitle}
          text={heroData?.fieldHeroDescription}
          image={isMobile ? heroData?.fieldBackgroundMobile?.url : heroData?.fieldBackgroundDesktop?.url }
          actionLabel={heroData?.fieldHeroCta.title}
        />
        <SectionsNav sections={ sections } offsetTop={ 132 } />
        <Section name='wine club' id='wine-club' ref={ wineClubRef }>
          <BlockTextSlider
            id="textcercle2"
            subtitle={ introData?.fieldWineClubIntroSubtitle }
            title={ introData?.fieldWineClubIntroTitle }
            text={ introData?.fieldWineClubIntroIntro }
            images={ introData?.fieldWineClubIntroImages }
            actions={ introBtns }
          />
        </Section>
        {/*{quoteData && <Quote*/}
        {/*  content={quote}*/}
        {/*/>}*/}
        <span
          class="benefits-a"
          id="benefits2"
          style={{
            display: 'block',
            height: '135px',
            marginTop: '-135px',
            visibility: 'hidden'
          }}
          />

        <Section name='benefits' id='benefits' ref={ membershipRef }>
          <Benefits
            subtitle={ benefits.subtitle }
            title={ benefits.title }
            text={ benefits.text }
            items={ benefits.list }
          />
        </Section>

        <Section name='events' id='events' ref={ eventsRef }>
          <EventsSlider
            subtitle={ eventData.subtitle }
            title={ eventData.title }
            text={ eventData.text }
            items={ eventItems }
          />
        </Section>


        <Section name='Club Selection' id='club-selection' ref={ selectionRef }>
          <BlockSlider
            id="textcercle2"
            subtitle={selectionData?.fieldWineClubSelectSubtitle}
            slideSelector={selectionSlidesData}
            text=""
            // actions={ [
            //   <Button key={ 1 } target="_blank" to={ resolveFileURL(selectionData?.fieldWineClubSelectDetails[0].entity?.fieldWineClubTastingNotes?.entity?.uri?.url)}>{selectionData?.fieldWineClubSelectDetails[0].entity?.fieldTastingNotesCtaLabel}</Button>
            // ] }
            images={ [] }
          />
        </Section>


        <Section name='Explore our wines' id='explore-our-wines' ref={ exploreRef }>
          <WinesSlider
            id="textcercle1"
            title={ winesData.title }
            subtitle={ winesData.subtitle }
            text={ winesData.text }
            items={ winesItems }
            linkTitle="Discover all the wines from the two Cloudy Bay wineries"
          />
        </Section>

        <Section name='faq' id='faq' ref={ faqRef }>
          <Faq
            title={faqData.fieldFaqTitle}
            subtitle={faqData.fieldFaqSubtitle}
            text={faqData.fieldFaqDescription}
            items={ faqItemsData }
          />
        </Section>

        {/* CONTACT */}
        <Section name='contact us' id='contact us' ref={ contactRef }>
          <Contact
            title={ contactData.title }
            subtitle={ contactData.subtitle }
            actions={[
              <Button key={ 1 } to={ contactData.action.url.path } target="_blank">{ contactData.action.title }</Button>
            ]}
            text={ contactData.text }
            image={ contactData.image }
            alt={ contactData.alt }
          />
        </Section>
        {/* WINERY */}
        <WinerySlider
          id="textcercle2a"
          secondId='textcercle2b'
          label={ data.drupal.nodeById.fieldWineClubVisitUsBlock.entity.fieldVisitUsSubtitle }
          items={ visitUsItems }
        />
      </Wrapper>
      {/*<StoreLocator pageType='Wine Club'/>*/}
    </Layout>
  )
}

export const query = graphql`
  query WineClubQuery {
    drupal {
      productsQuery: taxonomyTermQuery(
        limit: 1000,
        offset: 0,
        sort: [{field: "weight", direction: ASC}],
        filter: {conditions: [{operator: EQUAL, field: "vid", value: ["wine_category"]}]}
      ) {
        entities {
          ... on Drupal_TaxonomyTermWineCategory {
            name
            fieldWineCategorySlugUrl
            fieldWineCatPrimaryVintage {
              entity {
                ... on Drupal_NodeWineProduct {
                  nid
                  path {
                    alias
                  }
                  title
                  fieldProductDiscoverColorCta
                  fieldWineProductBackcolor
                  fieldWineProductBackillustrat {
                    url
                  }
                  fieldWineProductShortDesc
                  fieldWineProductName
                  fieldCategoryProductName
                  fieldWineProductDiscoverCta {
                    title
                  }
                  fieldWineProdPackshotShadow {
                    url
                  }
                }
              }
            }
            fieldWineCategoryFromPrice
          }
        }
      }
      nodeById(id: "13") {
        title
        ... on Drupal_NodeWineClub {
          fieldWineClubMetaTags {
            entity {
              ... on Drupal_ParagraphMetaTags {
                id
                fieldMetaSlugUrl
                fieldMetaTagsBypassAgeGate
                fieldMetaTitle
                fieldMetaOpenGraphImage {
                  url
                }
                fieldMetaDescription
                }
            }
          }
          fieldWineClubQuote
          fieldWineClubBenefits {
            entity {
              ... on Drupal_ParagraphWineClubBenefits {
                id
                fieldWineClubBenefitDesc
                fieldWineClubBenefitSubtitle
                fieldWineClubBenefitTitle
                fieldWineClubBenefitsList {
                  entity {
                    ... on Drupal_ParagraphWineClubBenefitsList {
                      fieldWineClubBenefitCat
                      fieldWclubTermsPopinText
                      fieldWineClubBenefitCat
                      fieldWclubTermsPopinTitle
                      fieldWclubTermsPopinText
                      fieldWineClubBenefitListLnk {
                        uri
                        title
                      }
                      fieldWineClubBenefitListTrm {
                        uri
                        title
                      }
                      fieldWineClubBenefitList {
                        entity {
                          ... on Drupal_ParagraphWineClubBenefitItem {
                            fieldWineClubBenefitLabel
                            fieldStandardDescription
                            fieldWineClubBenefitImage {
                              alt
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          fieldWineClubHeroBlock {
            entity {
              ... on Drupal_ParagraphHeroBlock {
                id
                fieldHeroTitle
                fieldHeroSubtitle
                fieldHeroDescription
                fieldHeroCta {
                  url {
                    path
                  }
                  title
                }
                fieldBackgroundMobile {
                  alt
                  url
                }
                fieldBackgroundDesktop {
                  alt
                  url
                }
              }
            }
          }
          fieldWineClubIntroBlock {
            entity {
              ... on Drupal_ParagraphWineClubIntro {
                id
                fieldWineClubIntroCta {
                  title
                  url {
                    path
                  }
                }
                fieldWineClubIntroIntro
                fieldWineClubIntroSubtitle
                fieldWineClubIntroTitle
                fieldWineClubIntroImages {
                  url
                  alt
                }
              }
            }
          }
          fieldWineClubSelectionBlock {
            entity {
              ... on Drupal_ParagraphWineClubSelectionList {
                id
                fieldWineClubSelectSubtitle
                fieldWineClubSelectDetails {
                  entity {
                    ... on Drupal_ParagraphWineClubSelectionDetails {
                      id
                      fieldWineClubSelectTitle
                      fieldWineClubSelectionDesc
                      fieldWineClubSelectionYear
                      fieldWineClubSelectionQuart
                      fieldWineClubSelectionMonth
                      fieldTastingNotesCtaLabel
                      fieldWineClubTastingNotes {
                        entity {
                          url
                          uri {
                            url
                          }
                        }
                      }
                      fieldWineClubSelectionVideo {
                        uri
                        title
                      }
                      fieldWineClubImageCarrousel {
                        entity {
                          id
                          ... on Drupal_ParagraphStandardImageCarrousel {
                            id
                            fieldCarrouselImageItem {
                              entity {
                                id
                                ... on Drupal_ParagraphCarrouselImageItem {
                                  id
                                  fieldImageItem {
                                    alt
                                    url
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      fieldWineClubWineList {
                        entity {
                          title
                          entityBundle
                          ... on Drupal_NodeWineClubDedicatedWines {
                            fieldWineProductSpecial
                          }
                          ... on Drupal_NodeWineProduct {
                            path {
                              alias
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          fieldWineClubFaqBlock {
            entity {
              ... on Drupal_ParagraphStandardFaqList {
                id
                fieldFaqDescription
                fieldFaqTitle
                fieldFaqSubtitle
                fieldFaqListItem {
                  entity {
                    ... on Drupal_ParagraphStandardFaqItem {
                      id
                      fieldFaqItemAnswer
                      fieldFaqItemQuestion
                    }
                  }
                }
              }
            }
          }
          fieldWineClubOurWinesTitle
          fieldWineClubOurWinesSub
          fieldWineClubOurWinesText
          fieldWineClubEventTitle
          fieldWineClubEventSubtitle
          fieldWineClubEventText
          fieldWineClubEventItems {
            entity {
              ... on Drupal_ParagraphEventItem {
                fieldEventItem {
                  entity {
                    ... on Drupal_NodeEvents {
                      title
                      path {
                        alias
                      }
                      fieldEventTitle
                      fieldEventStartDate { value }
                      fieldEventEndDate { value }
                      fieldEventLocation
                      fieldEventCardDisable
                      fieldEventCardLinkLabel
                      fieldEventCover {
                        alt
                        url
                      }
                      fieldEventCoverMobile {
                        alt
                        url
                      }
                      fieldEventCardLinkTock {
                        uri
                        url {
                          path
                        }
                        title
                      }
                    }
                  }
                }
              }
            }
          }
          fieldWineClubContactUsBlock {
            entity {
              ... on Drupal_ParagraphContactBlock {
                fieldContactBlockTitle
                fieldContactBlockSubtitle
                fieldContactBlockText
                fieldContactBlockLink {
                  uri
                  url {
                    path
                  }
                  title
                }
                fieldContactBlockImage {
                  alt
                  url
                }
              }
            }
          }
          fieldWineClubVisitUsBlock {
            entity {
              ... on Drupal_ParagraphVisitUsList {
                id
                fieldVisitUsSubtitle
                fieldVisitUsItemList {
                  entity {
                    ... on Drupal_ParagraphVisitUsItem {
                      id
                      fieldVisitUsItemTitle
                      fieldVisitUsItemCta {
                        title
                        url {
                          path
                        }
                      }
                      fieldVisitUsItemCoverImage {
                        alt
                        url
                      }
                      fieldVisitUsItemBackMobile {
                        alt
                        url
                      }
                      fieldVisitUsItemBackDesk {
                        alt
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default VisitWinery
