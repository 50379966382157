import styled from '@emotion/styled'

import { GridContainer as GridContainerBase } from '@/components/Grid'
import {
  ImageWrapper as CardImageWrapper,
  ContentWrapper as CardContentWrapper
} from '@/components/Block/Sliders/ExperienceSlider/ExperienceCard/style'
import WysiwygBase from '@/components/Wysiwyg'

import { mediaMax, sectionSubtitle } from '@/styles/mixins'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 7rem 0;
  ${mediaMax.xs} {
    margin: 0;
    margin-top: 5rem;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 2.4rem;
  ${mediaMax.xs} {
    gap: 3rem;
    flex-direction: column;
    align-items: flex-start;
  }
`

export const GridContainer = styled(GridContainerBase)`
  ${CardImageWrapper} {
    padding-top: 58%;
  }

  ${CardContentWrapper} {
    padding-bottom: 0;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`

export const Subtitle = styled.span`
  ${sectionSubtitle()}
  color: ${({ theme }) => theme.colors.lightGreen};
  margin-top: 2rem;
  margin-bottom: 2rem;
`

export const Title = styled.span`
  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: 4.5rem;
  font-weight: bold;
  line-height: 5.5rem;
  color: ${({ theme }) => theme.colors.brown};
  margin-bottom: 2.3rem;

  span {
    color: ${({ theme }) => theme.colors.green};
  }

  ${mediaMax.xs} {
    font-size: 4.2rem;
  }
`

export const Wysiwyg = styled(WysiwygBase)`  
  color: ${({ theme }) => theme.colors.brown};
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
`
