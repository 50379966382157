import React, { useState } from 'react'
import PropTypes from 'prop-types'

import BlockImageSlider from '@/components/Block/Sliders/ImageSlider'
import BlockText from '@/components/Block/Text'
import CircularLabelButton from '@/components/CircularLabelButton'

import {
  Container,
  LeftWrapper,
  RightWrapper
} from './style'

const TextSlider = ({
  id,
  title = '',
  subtitle = '',
  text,
  images = [],
  display = 'regular',
  actions = [],
  insertAfterText = [],
  circularLabelButton = null,
  slideSelector = [null]
}) => {
  const defaultSlide = {
    title,
    subtitle,
    text
  }
  const [active, setActive] = useState(slideSelector[0] || defaultSlide)

  const updateSelectSlide = (date) => {
    setActive(slideSelector.find((i) => i.selector === date))
  }

  const selectData = slideSelector[0] ? { label: slideSelector[0].selector, items: slideSelector[0].list, url: slideSelector[0].url, ctaLabel: slideSelector[0].ctaLabel, recommendation: slideSelector[0].recommendation, source: slideSelector[0].source, videoLabel: slideSelector[0].videoLabel, onchange: updateSelectSlide } : false
  return (
    <Container className={ `${display}` }>
      <LeftWrapper>
        <BlockImageSlider
          display={ display }
          images={ images.length > 0 ? images : active.images }
        />

        {circularLabelButton && (
          <CircularLabelButton
            id={ id || 'textcercle' }
            label={ circularLabelButton.label }
            nbLabels={ circularLabelButton.nbLabels }
            to={ circularLabelButton.to }
            target={ circularLabelButton.target }
            className='circularLabelButton'
          />
        )}
      </LeftWrapper>
      <RightWrapper>
        <BlockText
          title={ active.title }
          titleTag='h2'
          subtitle={ subtitle }
          text={ active.text }
          actions={ actions }
          insertAfterText={ insertAfterText }
          slideSelector={ selectData }
          active={ active }
        />
      </RightWrapper>
    </Container>
  )
}

TextSlider.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.node.isRequired,
  actions: PropTypes.array,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  display: PropTypes.oneOf(['regular', 'inverted']),
  insertAfterText: PropTypes.array
}

export default TextSlider
