import styled from '@emotion/styled'

import {
  Content as QuoteContent
} from '@/components/Block/Quote/style'

import {
  Subtitle as BlockSubtitle,
  Title as BlockTitle
} from '@/components/Block/Text/style'

import {
  Container as BenefitsContainer,
  Subtitle as BenefitsSubTitle,
  Title as BenefitsTitle
} from '@/components/Benefits/style'

import {
  Title as FaqTitle
} from '@/components/Faq/style'

import {
  Wrapper as CircularLabelButtonWrapper,
  Label as CircularLabelButtonLabel
} from '@/components/CircularLabelButton/style'

import {
  ButtonWrapper,
  ButtonLabel
} from '@/components/Button/style'

// ORANGE = #dd8153
const ORANGE = '#dd8153'
// BLUE = #3f787b
const BLUE = '#3f787b'

export const Wrapper = styled.div`
  width: 100%;
  // ${QuoteContent} {
  //   color: ${BLUE};
  // }
  //
  // ${FaqTitle},
  // ${BenefitsTitle},
  // ${BlockTitle} {
  //   color: ${ORANGE};
  // }
  //
  // ${BlockSubtitle},
  // ${BenefitsSubTitle} {
  //   color: ${({ theme }) => theme.colors.brown};
  // }
  //
  // ${BenefitsContainer} {
  //   ${CircularLabelButtonWrapper} {
  //     ${CircularLabelButtonLabel} {
  //       fill: ${BLUE};
  //     }
  //
  //     .icon-arrow-right {
  //       path {
  //         fill: ${BLUE} !important;
  //       }
  //     }
  //   }
  // }
  //
  // .icon-grape {
  //   path {
  //     fill: ${ORANGE} !important;
  //   }
  // }
  //
  // ${ButtonWrapper} {
  //   &.green {
  //     background-color: ${BLUE};
  //     border: 1px solid ${BLUE};
  //
  //     &:hover {
  //       ${ButtonLabel} {
  //         color: ${BLUE};
  //       }
  //       background-color: transparent;
  //     }
  //   }
  // }
`
