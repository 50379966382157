import styled from '@emotion/styled'

import { mediaMax } from '@/styles/mixins'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;

  .cta-experience{
    margin-top: 1rem;
    max-width: 180px;
    ${mediaMax.xs}{
      margin-top: 0;
    }
  }
`

export const SubContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`

export const ImageWrapper = styled.div`
  width: 100%;
  padding-top: 110%;
  position: relative;
`

export const IconWrapper = styled.a`
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 1;
  cursor: pointer;
`

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
`

export const ContentWrapper = styled.div`
  width: 100%;
  padding: 2rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  ${mediaMax.xs}{
    padding: 1.6rem 0;
  }
`

export const TagsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1.5rem;
  ${mediaMax.xs}{
    // margin-bottom: 1.6rem;
  }
`

export const Tag = styled.span`
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 1.4rem;
  letter-spacing: .1rem;
  color: ${({ theme }) => theme.colors.lightGreen};
  text-transform: none;
  padding: 1rem 0rem;
  text-transform: uppercase;
  ${mediaMax.xs}{
    font-size: 1.2rem;
  }
`

export const InfosWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`

export const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.serif};
  font-size:  3rem;
  line-height: 3.5rem;
  color: ${({ theme }) => theme.colors.brown};
  text-decoration: none;
  cursor: pointer;
`

export const Price = styled.span`
  margin-top: 1rem;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.brown};
`

export const PriceValue = styled.span`
  font-family: ${({ theme }) => theme.fonts.serif};
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
